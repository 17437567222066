import React from 'react';
import Homebar from '../components/Homebar';
import VideoPlayer from '../components/YoutubeVideo';
import InformationsModal from '../components/InformationsModal';
import { getPictures, getVideos, getCgv, getLegalMentions, getDescription } from '../routes/requests';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import AddImageModal from '../components/AddImageModal';
import FadeInText from '../components/FadeInText';
import Map from '../components/Map';
import Background from '../components/Background';
import CarouselSlider from '../components/Slider';

const Home = () => {
    const [cgv, setCgv] = React.useState("");
    const [legal, setLegal] = React.useState("");
    const [showModal, setShowModal] = React.useState("");
    const [pictureType, setPictureType] = React.useState("");
    
    const [backgroundPicture, setBackgroundPicture] = React.useState("");

    const [videos, setVideos] = React.useState([]);

    const [hasCookie, setHasCookie] = React.useState(false);

    const [description, setDescription] = React.useState("");
    const [prestations, setPrestations] = React.useState("");
    const [composition, setComposition] = React.useState("");
    const [interesses, setInteresses] = React.useState("");

    const navigate = useNavigate();

    const changePage = (page) => {
        navigate(page);
    };

    const toggleModal = () => {
        setShowModal("");
    };

    const openPictureAdd = (type) => {
        setPictureType(type);
        setShowModal("picture");
    };

    const openCgv = () => {
        setShowModal("cgv");
    }

    const openLegal = () => {
        setShowModal("legal");
    }

    const getText = (type) => {
        getDescription(type).then((content) => {
            const text = content.data;
            const textList = text.split('\n');
        
            for (let i = 0; i < textList.length; i++) {
                if (textList[i].trim().startsWith('-')) {
                    textList[i] = textList[i].replace('-', '•');
                }
            }
            
            if (type === 'prestations') setPrestations(textList.join('\n'));
            if (type === 'composition') setComposition(textList.join('\n'));
            if (type === 'description') setDescription(textList.join('\n'));
            if (type === 'interesses') setInteresses(textList.join('\n'));
        });
    }

    React.useEffect(() => {
        const getPicturesApi = async () => {
            const result = await getPictures();
            setBackgroundPicture(result.filter((elem) => elem.location === "accueil").at(0).file);
        };

        const getVideosApi = async () => {
            const result = await getVideos();
            setVideos([...result]);
        };

        const getCgvLegal = async () => {
            let result = await getCgv();
            setCgv(result.content);
            result = await getLegalMentions();
            setLegal(result.content);
        }

        getPicturesApi();
        getVideosApi();
        getCgvLegal();
        getText('prestations');
        getText('composition');
        getText('description');
        getText('interesses');

        if (Cookies.get("login")) {
            setHasCookie(true);
        }
    // eslint-disable-next-line
    }, []);

    return (
        <Background>
            <Homebar />

            {/* Contenu de la page */}
            <div className="flex flex-col items-center justify-center">

                {/* Image accueil */}
                <div className='w-full bg-cover bg-center h-screen flex flex-col items-center justify-between' style={{
                    backgroundImage: `url("https://penacamargua.fr:8000/static/${backgroundPicture}")`,
                    height: 'calc(100vh - 56px)'
                }}>
                    <div>
                        <h1 
                            className="text-6xl font-bold text-center mb-8 text-mainBlue text-stroke"
                        >
                            Peña Camargua
                        </h1>
                    </div>
                    <div>
                        <button 
                            className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 hover:bg-secondBlue'
                            onClick={() => changePage("/boutique")}
                        >
                            Acheter notre album
                        </button>
                    </div>
                </div>

                <div className='w-full h-auto pb-fivePercent'>
                    <FadeInText>
                        <h1 className='text-4xl font-bold px-[5%] mt-4 text-white'>Notre objectif</h1>
                    </FadeInText>
                    <FadeInText>
                        <pre className='text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                            {description}
                        </pre>
                    </FadeInText>
                </div>

                <div className='w-full h-auto pb-fivePercent'>
                    <FadeInText>
                        <h1 className='text-4xl font-bold px-[5%] mt-4 text-white'>Composition de l'équipe</h1>
                    </FadeInText>
                    <FadeInText>
                        <pre className='text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                            {composition}
                        </pre>
                    </FadeInText>
                </div>

                <div className='w-full h-auto pb-fivePercent'>
                    <FadeInText>
                        <h1 className='text-4xl font-bold px-[5%] mt-4 text-white'>Les prestations</h1>
                    </FadeInText>
                    <FadeInText>
                        <pre className='text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                            {prestations}
                        </pre>
                    </FadeInText>
                </div>

                <div className='w-full h-auto pb-tenPercent'>
                    <FadeInText>
                        <h1 className='text-4xl font-bold px-[5%] mt-4 text-white'>Intéressé.es ?</h1>
                    </FadeInText>
                    <FadeInText>
                        <pre className='text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                            {interesses}
                        </pre>
                    </FadeInText>
                </div>

                <div className='w-full h-auto pb-fivePercent'>
                    <h1 className='text-4xl font-bold px-[5%] mt-4 text-center text-white'>Où nous trouver ?</h1>
                    <Map />
                </div>

                <div className='w-full h-auto pb-fivePercent mt-20 overflow-hidden'>
                <h1 className='text-4xl font-bold text-center mb-3 text-white'>Photos</h1>
                    <CarouselSlider 
                        slide={'prestations'}
                    />
                    {
                    hasCookie ?
                        <div className="flex justify-center">
                            <button 
                            className="bg-mainBlue text-white font-bold px-8 py-3 rounded-full mt-7"
                            onClick={() => openPictureAdd('prestations')}
                            >
                            Ajouter une photo
                            </button>
                        </div> : <></>
                    }
                    <CarouselSlider 
                        slide={'ferias'}
                    />
                    {
                    hasCookie ?
                        <div className="flex justify-center">
                            <button 
                            className="bg-mainBlue text-white font-bold px-8 py-3 rounded-full mt-7"
                            onClick={() => openPictureAdd('ferias')}
                            >
                            Ajouter une photo
                            </button>
                        </div> : <></>
                    }
                    <CarouselSlider 
                        slide={'events'}
                    />
                    {
                    hasCookie ?
                        <div className="flex justify-center">
                            <button 
                            className="bg-mainBlue text-white font-bold px-8 py-3 rounded-full mt-7"
                            onClick={() => openPictureAdd('events')}
                            >
                            Ajouter une photo
                            </button>
                        </div> : <></>
                    }
                    <CarouselSlider 
                        slide={'mariages'}
                    />
                    {
                    hasCookie ?
                        <div className="flex justify-center">
                            <button 
                            className="bg-mainBlue text-white font-bold px-8 py-3 rounded-full mt-7"
                            onClick={() => openPictureAdd('mariages')}
                            >
                            Ajouter une photo
                            </button>
                        </div> : <></>
                    }
                </div>

                {/* Photos et vidéos */}
                <div className='w-full h-auto'>
                    <div className='mt-16'>
                        <h1 className='text-4xl font-bold text-center mb-3 text-white'>Mais surtout, écoutez-nous</h1>
                        {
                            videos.map((id, index) => {
                                return <VideoPlayer
                                    key={index}
                                    id_str={id}
                                />
                            })
                        }
                    </div>
                </div>
                <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 hover:bg-secondBlue'
                onClick={openLegal}
            >
                Mentions légales
            </button>
            <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-1 hover:bg-secondBlue'
                onClick={openCgv}
            >
                Conditions Générales de Vente
            </button>
            </div>
            {showModal === "cgv" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={cgv}
                            title={"Conditions générales de vente"}
                        />
                    )}
                    {showModal === "legal" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={legal}
                            title={"Mentions légales"}
                        />
                    )}
                    {showModal === "picture" && (
                        <AddImageModal 
                            type={pictureType}
                            onClose={toggleModal}
                        />
                    )

                    }
        </Background>
    );
};

export default Home;