import React from 'react';
import { addShopElements } from '../routes/requests';
import Cookies from 'js-cookie';

function ModalForm({ onClose }) {

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const sendFormData = new FormData();
    
        const image = formData.get("image_picture");
        if (image.type.startsWith("image/")) {
            sendFormData.append("image", image);
            sendFormData.append("name", formData.get("product_title"));
            const price = Number(formData.get("product_price"));
            sendFormData.append("price", price);
            sendFormData.append("link", formData.get("buy_link"));
            
            addShopElements(Cookies.get("login"), sendFormData).then((result) => {
                if (result) {
                  onClose();
                  window.location.reload();
                }
            })
        }
      };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-darkGray rounded-lg p-8">
        <h2 className="text-2xl font-bold mb-4 text-white">Ajouter un produit</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nom du produit</label>
            <input type="text" id="product_title" name='product_title'
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Mon nouveau produit"
                required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Prix (€)</label>
            <input type="text" id="product_price" name='product_price'
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="10.00"
                required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lien d'achat</label>
            <input type="text" id="buy_link" name='buy_link'
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="stripe.com/product"
                required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Image du produit</label>
            <input type="file" id="image_picture" name='image_picture'
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder=""
                required
            />
          </div>
          <div className="flex justify-end mt-4">
            <button type="submit" className="bg-mainBlue text-white px-4 py-2 rounded-md font-bold">Créer un produit</button>
            <button type="button" onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded-md font-bold ml-2">Annuler</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ModalForm;
