import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/Home';
import Boutique from './pages/Boutique';
import Contact from './pages/Contact';
import Success from './pages/Success';
import Admin from './pages/Admin';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/boutique",
    element: <Boutique />
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/success",
    element: <Success />
  },
  {
    path: "/admin",
    element: <Admin />
  },
  {
    path: "*",
    element: <Home />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
