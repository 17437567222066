import React from "react";

const InformationsModal = ({
    title,
    description,
    onClose,
}) => {
    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-darkGray rounded-lg p-8">
            <h2 className="text-2xl font-bold mb-4 text-white">{title}</h2>
                <p className="text-white">{description}</p>
                <div className="flex justify-end mt-4">
                    <button type="button" onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded-md font-bold ml-2">Fermer</button>
                </div>
          </div>
        </div>
    );
};
export default InformationsModal;
