import React from "react";
import { getVideos, updatePassword } from "../routes/requests";
import Cookies from "js-cookie";
import {
    getLegalMentions,
    getCgv,
    getDescription,
    updateLegalMentions,
    updateDescription,
    updateCgv,
    updatePictures,
    deletePictures,
    updateVideos,
} from "../routes/requests";

const AdminComponent = () => {
    const [passwordChangeMessage, setPasswordChangeMessage] = React.useState("");
    const [pictureChangeMessage, setPictureChangeMessage] = React.useState("");
    const [legalMentionsChangeMessage, setLegalMentionsChangeMessage] = React.useState("");

    const [descriptionChangeMessage, setDescriptionChangeMessage] = React.useState("");
    const [compositionChangeMessage, setCompositionChangeMessage] = React.useState("");
    const [prestationsChangeMessage, setPrestationsChangeMessage] = React.useState("");
    const [interessesChangeMessage, setInteressesChangeMessage] = React.useState("");

    const [cgvChangeMessage, setCgvChangeMessage] = React.useState("");
    const [videosChangeMessage, setVideosChangeMessage] = React.useState("");

    const [legalMentions, setLegalMentions] = React.useState("");
    const [cgv, setCgv] = React.useState("");

    const [description, setDescription] = React.useState("");
    const [composition, setComposition] = React.useState("");
    const [prestations, setPrestations] = React.useState("");
    const [interesses, setInteresses] = React.useState("");

    const [videos, setVideos] = React.useState("");

    React.useEffect(() => {
        getLegalMentions().then((response) => {
            setLegalMentions(response.content);
        });

        getCgv().then((response) => {
            setCgv(response.content);
        });

        getDescription('description').then((response) => {
            setDescription(response.data);
        });

        getDescription('composition').then((response) => {
            setComposition(response.data);
        });

        getDescription('prestations').then((response) => {
            setPrestations(response.data);
        });

        getDescription('interesses').then((response) => {
            setInteresses(response.data);
        });

        getVideos().then((response) => {
            // https://www.youtube.com/watch?v=
            for (let i = 0; i < response.length; i++) {
                response[i] = "https://www.youtube.com/watch?v=" + response[i];
            }
            setVideos(response.join("\n"));
        });

    }, []);

    const updateLegalMentionsForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const legalMentions = formData.get("legal_mentions");

        updateLegalMentions(Cookies.get("login"), legalMentions).then((result) => {
            if (result) {
                setLegalMentionsChangeMessage("Mentions légales modifiées.");
            }
        });
    };

    const updateDescriptionForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const description = formData.get("description");

        updateDescription(Cookies.get("login"), description, 'description').then((result) => {
            if (result) {
                setDescriptionChangeMessage("La description du groupe a été modifiée.");
            }
        });
    };

    const updateCompositionForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const description = formData.get("description");

        updateDescription(Cookies.get("login"), description, 'composition').then((result) => {
            if (result) {
                setCompositionChangeMessage("La composition du groupe a été modifiée.");
            }
        });
    };

    const updatePrestationsForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const description = formData.get("description");

        updateDescription(Cookies.get("login"), description, 'prestations').then((result) => {
            if (result) {
                setPrestationsChangeMessage("La prestation du groupe a été modifiée.");
            }
        });
    };
    
    const updateInteressesForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const description = formData.get("description");

        updateDescription(Cookies.get("login"), description, 'interesses').then((result) => {
            if (result) {
                setInteressesChangeMessage("Le texte intéressés du groupe a été modifiée.");
            }
        });
    };

    const updatePasswordForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const newPassword = formData.get("new_password");

        updatePassword(Cookies.get("login"), newPassword).then((result) => {
            if (result) {
                setPasswordChangeMessage("Le mot de passe a été changé.");
            }
        });
    };

    const updateCgvForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const cgv = formData.get("cgv");

        updateCgv(Cookies.get("login"), cgv).then((result) => {
            if (result) {
                setCgvChangeMessage("Les CGV ont été modifiés.");
            }
        });
    };

    const updatePictureForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const sendFormData = new FormData();

        if (formData.get("title_picture").type.startsWith("image/")) {
            sendFormData.append('image', formData.get("title_picture"));
            sendFormData.append('type', "accueil");
            deletePictures(Cookies.get("login"), "accueil").then((result) => {
                updatePictures(Cookies.get("login"), sendFormData).then((result) => {
                    if (result) {
                        setPictureChangeMessage("La photo d'accueil a été modifiée.");
                    }
                });
            });
        }
    };

    const updateVideosForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        const videos = formData.get("videos");
        const splitted_videos = videos.replaceAll("https://www.youtube.com/watch?v=", "").split("\n");
        updateVideos(splitted_videos, Cookies.get("login")).then((result) => {
            if (result) {
                setVideosChangeMessage("Les vidéos ont été mises à jour.");
            }
        });
    };

    return <div>
        <h1
            className="text-white text-center text-4xl font-bold mt-4"
        >
            Panneau d'administration
        </h1>
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <form
                action="#"
                className="space-y-8"
                onSubmit={updatePasswordForm}
            >
                <div>
                    <label className="block mb-2 text-sm font-mediu text-gray-300">Nouveau mot de passe</label>
                    <input type="password" id="new_password" name="new_password"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        placeholder="Votre nouveau mot de passe"
                        required
                    />
                </div>
                <button type="submit"
                    className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-16 hover:bg-secondBlue"
                >
                    Changer de mot de passe
                </button>
                <p className="text-green-300">{passwordChangeMessage}</p>
            </form>
        </div>
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <form
                action="#"
                className="space-y-8"
                onSubmit={updatePictureForm}
            >
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-300">Image d'accueil</label>
                    <input type="file" id="title_picture" name="title_picture"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        required
                    />
                </div>
                <button type="submit"
                    className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-16 hover:bg-secondBlue"
                >
                    Changer la photo d'accueil
                </button>
                <p className="text-green-300">{pictureChangeMessage}</p>
            </form>
        </div>
        <div
            className="sm:col-span-2"
        >
            <form
                action="#"
                className="space-y-8"
                onSubmit={updateDescriptionForm}
            >
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-400"
                    >
                        Modification de la description du groupe
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Description"
                        required
                        defaultValue={description}
                    ></textarea>
                    <button type="submit"
                        className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-8 hover:bg-secondBlue"
                    >
                        Changer la description
                    </button>
                </div>
            </form>
            <p className="text-green-300">{descriptionChangeMessage}</p>
        </div>
        <div
            className="sm:col-span-2"
        >
            <form
                action="#"
                className="space-y-8"
                onSubmit={updateCompositionForm}
            >
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-400"
                    >
                        Modification de la composition du groupe
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Description"
                        required
                        defaultValue={composition}
                    ></textarea>
                    <button type="submit"
                        className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-8 hover:bg-secondBlue"
                    >
                        Changer la composition
                    </button>
                </div>
            </form>
            <p className="text-green-300">{compositionChangeMessage}</p>
        </div>
        <div
            className="sm:col-span-2"
        >
            <form
                action="#"
                className="space-y-8"
                onSubmit={updatePrestationsForm}
            >
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-400"
                    >
                        Modification des prestations du groupe
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Description"
                        required
                        defaultValue={prestations}
                    ></textarea>
                    <button type="submit"
                        className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-8 hover:bg-secondBlue"
                    >
                        Changer les prestations
                    </button>
                </div>
            </form>
            <p className="text-green-300">{prestationsChangeMessage}</p>
        </div>
        <div
            className="sm:col-span-2"
        >
            <form
                action="#"
                className="space-y-8"
                onSubmit={updateInteressesForm}
            >
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-400"
                    >
                        Modification du texte intéressés du groupe
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Description"
                        required
                        defaultValue={interesses}
                    ></textarea>
                    <button type="submit"
                        className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-8 hover:bg-secondBlue"
                    >
                        Changer le texte intéressés
                    </button>
                </div>
            </form>
            <p className="text-green-300">{interessesChangeMessage}</p>
        </div>
        <div
            className="sm:col-span-2"
        >
            <form
                action="#"
                className="space-y-8"
                onSubmit={updateLegalMentionsForm}
            >
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-400"
                    >
                        Modification des mentions légales
                    </label>
                    <textarea
                        id="legal_mentions"
                        name="legal_mentions"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Mentions légales"
                        required
                        defaultValue={legalMentions}
                    ></textarea>
                    <button type="submit"
                        className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-8 hover:bg-secondBlue"
                    >
                        Changer les mentions légales
                    </button>
                </div>
            </form>
            <p className="text-green-300">{legalMentionsChangeMessage}</p>
        </div>
        <div
            className="sm:col-span-2"
        >
            <form
                action="#"
                className="space-y-8"
                onSubmit={updateCgvForm}
            >
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-400"
                    >
                        Modification des CGV
                    </label>
                    <textarea
                        id="cgv"
                        name="cgv"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="CGV"
                        required
                        defaultValue={cgv}
                    ></textarea>
                    <button type="submit"
                        className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-8 hover:bg-secondBlue"
                    >
                        Changer les CGV
                    </button>
                </div>
            </form>
            <p className="text-green-300">{cgvChangeMessage}</p>
        </div>
        <div
            className="sm:col-span-2"
        >
            <form
                action="#"
                className="space-y-8"
                onSubmit={updateVideosForm}
            >
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-400"
                    >
                        Liste des vidéos
                    </label>
                    <textarea
                        id="videos"
                        name="videos"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Liste des vidéos séparées par un saut de ligne"
                        required
                        defaultValue={videos}
                    ></textarea>
                    <button type="submit"
                        className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-8 hover:bg-secondBlue"
                    >
                        Changer la liste des vidéos
                    </button>
                </div>
            </form>
            <p className="text-green-300">{videosChangeMessage}</p>
        </div>
    </div>;
};
export default AdminComponent;