import React from "react";
import { login } from "../routes/requests";
import Cookies from "js-cookie";

const Login = ({
    setHasCookie
}) => {
    const loginForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const password = formData.get("password");

        login(password).then((response) => {
            if (response) {
                const expires = new Date();
                expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000);
                Cookies.set("login", response, { expires: expires });
                setHasCookie(true);
            }
        });
    };

    return <div>
        <h1
            className="text-white text-center text-4xl font-bold mt-4"
        >
            Connectez-vous
        </h1>
        <div
            className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md"
        >
            <form
                action="#"
                className="space-y-8"
                onSubmit={loginForm}
            >
                <div>
                    <label
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Mot de passe
                    </label>
                    <input 
                        type="password"
                        id="password"
                        name="password"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        required
                    />
                </div>
                <button type="submit"
                    className="bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-16 hover:bg-secondBlue"
                >
                    Se connecter
                </button>
            </form>
        </div>
    </div>;
};
export default Login;