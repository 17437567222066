import React from "react";
import Homebar from "../components/Homebar";

const Success = () => {
    return <div className="bg-black min-h-screen">
        <Homebar />
        <h1 className="text-4xl font-bold text-center mt-4 text-white">
            Merci pour votre achat !
        </h1>
        <p className="text-white text-center mt-6">
            Vous recevrez votre commande d'ici une à deux semaines.
        </p>
    </div>;
};
export default Success;