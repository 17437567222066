import React, { useEffect } from 'react';

const FadeInText = ({ children }) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const ref = React.useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(ref.current);
                }
            }, { threshold: 0.5 }
        )
        if (ref.current) {
            observer.observe(ref.current);
        }
        
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return <div
        ref={ref}
        className={`fade-in-left ${isVisible ? 'visible' : ''}`}
    >
        {children}
    </div>;

};
export default FadeInText;