import React from "react";
import Cookies from "js-cookie";
import Homebar from "../components/Homebar";
import Login from "../components/Login";
import AdminComponent from "../components/AdminComponent";

const Admin = () => {
    const [hasCookie, setHasCookie] = React.useState(null);
    
    React.useEffect(() => {
        const jwtCookie = Cookies.get("login");

        if (jwtCookie) {
            setHasCookie(true);
        } else {
            setHasCookie(false);
        }
    }, [])

    return <div
        className="bg-black min-h-screen"
    >
        <Homebar />
        { hasCookie ?
        <AdminComponent />
            : 
        <Login 
            setHasCookie={setHasCookie}
        /> }
    </div>

};
export default Admin;