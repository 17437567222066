import React from "react";

const VideoPlayer = ({
  id_str
}) => {
  return <div
    className="flex flex-wrap video-responsive items-center justify-center mt-3"
  >
    <iframe 
      width={"853"}
      height={"480"}
      src={`https://www.youtube.com/embed/${id_str}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={id_str}
    />
  </div>;
}
export default VideoPlayer;

