import React from "react";

const PurchaseItem = ({ 
    linkButton,
    linkImage,
    productName,
    price,
    hasCookies,
    deleteElement,
    number
}) => {
    if (linkButton.length === 0 || linkImage.length === 0 || productName.length === 0 || price === 0) {
        console.error("Invalid parameters for a PurchaseItem");
        throw Error("Invalid parameters for a PurchaseItem");
    };

    return <div 
        className="bg-darkGray block mt-4 mx-5 rounded-xl relative"
        style={{
            maxWidth: "33vh",
            minWidth: "20vh",
        }}
    >
        {hasCookies ? 
            <button className="absolute top-0 right-0 m-2" onClick={() => {
                deleteElement(number);
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 12.586l-3.293 3.293-1.414-1.414L8.586 11 5.293 7.707l1.414-1.414L10 9.172l3.293-3.293 1.414 1.414L11.414 11l3.293 3.293-1.414 1.414L10 12.586z" clipRule="evenodd" />
                </svg>
            </button> : <></>
        }
        
        <div
            className="bg-inherit flex justify-center items-center rounded-xl"
        >
            <img src={"https://penacamargua.fr:8000" + linkImage} className="rounded-t-lg" alt="Produit"/>
        </div>
        <h4
            className="text-white text-xl font-medium text-center bg-inherit"
        >
            { productName }
        </h4>
        <div
            className="bg-inherit flex justify-center items-center pt-3"
        >
            <button
                className="inline-block rounded-md bg-mainBlue text-white text-center justify-center px-6 pt-2 pb-2
                font-bold hover:bg-secondBlue"
                onClick={() => {
                    return window.open(linkButton);
                }}
            >
                Acheter
            </button>
        </div>
        <p
            className="pt-4 text-white text-center bg-inherit rounded-xl"
        >
            { price.toFixed(2) } €
        </p>
    </div>;
};
export default PurchaseItem;