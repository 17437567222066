import React from "react";

const Background = ({children, className=null}) => {
    return <div
        className={`bg-black ${className}`}
    >
        <div className={`bg-secondBlueTransparent ${className}`}>
            {children}
        </div>
    </div>
};
export default Background;
