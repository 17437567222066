import React from "react";
import Slider from "react-slick";
import { deletePictures, getPictures } from "../routes/requests";
import Cookies from 'js-cookie';

const CarouselSlider = ({
  slide
}) => {
  const removePicture = (name) => {
    deletePictures(Cookies.get("login"), name).then((result) => {
        if (result) {
            window.location.reload();
        }
    })
  };

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
  };

  const [slides, setSlides] = React.useState([]);
  const [hasCookie, setHasCookie] = React.useState(false);

  React.useEffect(() => {
    if (Cookies.get("login")) {
      setHasCookie(true);
    }
    const getSlides = async () => {
      const pictures = await getPictures();
      if (pictures.length === 0) return [];
      const picturesFiltered = pictures.filter((picture) =>
        picture.location !== 'accueil' && picture.location !== 'shop'
      ) 
      const tempSlides = picturesFiltered.map((picture) => {
      if (picture.location !== 'accueil' && picture.location !== 'shop') {
        const elementToReturn = {
          src: picture.file,
          text: ''
        }
        if (picture.location === 'prestations') elementToReturn.text = 'Prestations taurines';
        else if (picture.location === 'ferias') elementToReturn.text = 'Fêtes de villages / Férias';
        else if (picture.location === 'events') elementToReturn.text = 'Evènement divers';
        else elementToReturn.text = 'Mariages / Anniversaires';
        return elementToReturn;
      }
      return;
      });
      console.log(tempSlides);
      if (tempSlides.length > 0 && !tempSlides[0]) return [];
      return tempSlides;
    };
  
    getSlides().then((tempSlides) => {
      if (tempSlides.length === 0) return;
      if (slide === 'prestations') {
        setSlides(tempSlides.filter((aSlide) => aSlide.text === 'Prestations taurines'));
      } else if (slide === 'ferias') {
        setSlides(tempSlides.filter((aSlide) => aSlide.text === 'Fêtes de villages / Férias'));
      } else if (slide === 'events') {
        setSlides(tempSlides.filter((aSlide) => aSlide.text === 'Evènement divers'));
      } else {
        setSlides(tempSlides.filter((aSlide) => aSlide.text === 'Mariages / Anniversaires'));
      }
    });
  }, []);

  if (slides && slides.length > 1) {
    return (
      <Slider {...settings} className="text-white relative mt-10">
        {slides.map((sl, key) => {
          return (
            <div key={key} className="flex justify-center">
              <div className="flex justify-center items-center w-full h-full relative">
                {/* Bouton supprimer */}
                {hasCookie && (
                  <button
                    onClick={() => removePicture(sl.src)}
                    className="absolute top-2 right-2 bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 z-10"
                  >
                    Supprimer
                </button>
                )}
                <img
                  src={"https://penacamargua.fr:8000/static/" + sl.src}
                  className="w-[70%] h-[70vh] object-contain"
                />
                {/* Banderole centrée sur l'image */}
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-60 text-center p-2">
                  <h2 className="text-white font-bold">{sl.text}</h2>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  } else if (slides && slides.length > 0) {
    return (
      <div key={1} className="flex justify-center mt-10">
        <div className="flex justify-center items-center w-full h-full relative">
        {hasCookie && (
                  <button
                    onClick={() => removePicture(slides[0].src)}
                    className="absolute top-2 right-2 bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 z-10"
                  >
                    Supprimer
                </button>
                )}
          <img
            src={"https://penacamargua.fr:8000/static/" + slides[0].src}
            className="w-[70%] h-[70vh] object-contain"
          />
          {/* Banderole centrée sur l'image */}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-60 text-center p-2">
            <h2 className="text-white font-bold">{slides[0].text}</h2>
          </div>
        </div>
      </div>
    ); 
  } else {
    return <></>;
  }
};

export default CarouselSlider;
