import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {Icon} from 'leaflet';

const icon = new Icon({
    iconUrl: 'marker.png',
    iconSize: [23.3, 33.3],
    iconAnchor: [25, 25],
    popupAnchor: [-12, -30],
});

const Map = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2vh'
            }}
        >
            <MapContainer 
                center={[43.7530, 4.2263]}
                zoom={9}
                style={{
                    height: '60vh',
                    width: '60vw',
                }}
            >
                <TileLayer 
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker 
                    position={[43.676373, 4.627613]} icon={icon}
                >
                    <Popup>Arles</Popup>
                </Marker>
                <Marker
                    position={[43.690914, 4.275347]} icon={icon}
                >
                    <Popup>Vauvert</Popup>
                </Marker>
                <Marker
                    position={[43.836702, 4.360138]} icon={icon}
                >
                    <Popup>Nîmes</Popup>
                </Marker>
                <Marker
                    position={[43.598595, 3.896261]} icon={icon}
                >
                    <Popup>Montpellier</Popup>
                </Marker>
            </MapContainer>
        </div>
    );

};
export default Map;