import React from "react";
import { useNavigate } from 'react-router-dom'

const Homebar = () => {
    const navigate = useNavigate();

    const changePage = (page) => {
        navigate(page);
    };

    return <div 
        className='w-screen h-14 bg-darkGray flex items-center justify-between p-4'>
        {/* Logo */}
        <div className='text-white hover:cursor-pointer'
            onClick={() => changePage("/")}
        >
            <img src="penacamargua.png" alt="Logo" className='h-11' />
        </div>

        {/* Boutons texte */}
        <div className='flex space-x-4'>
            <button className='text-mainBlue hover:text-secondBlue text-stroke' onClick={() => changePage("/")}>Accueil</button>
            <button className='text-mainBlue hover:text-secondBlue text-stroke' onClick={() => changePage("/contact")}>Nous contacter</button>
            <button className='text-mainBlue hover:text-secondBlue text-stroke' onClick={() => changePage("/boutique")}>Boutique</button>
        </div>
    </div>
};
export default Homebar;